const course = [
    {
        name: 'Crypto from A to Z',
        desc: 'A fundamental course on cryptocurrencies for beginners',
        progress: 0,
        isStarted:false,
        price:0,
        membersCount:24,
        lessonsCount:28,
        overview:[
            {
                heading: 'What is P2P?',
                content:[
                    {   title: 'What is P2P?',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'Definitions and terms',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'Who are the merchants',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'The tax service',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },{   title: 'Equipment for P2P',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    }
                ]
            },
            {
                heading: 'Safety',
                content:[
                    {   title: 'What is Safety?',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'Definitions and terms',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'Who are the merchants',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },
                    {   title: 'The tax service',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    },{   title: 'Equipment for P2P',
                        text: 'Yes. It`s animated by default, but you can disable it if you prefer.',
                    }
                ]
            },
            
        ]
    }
    
]
export { course };